import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';

import { Form, LocationAutocompleteInput } from '../../../../components';

import searchIcon from './img/search.png';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';
import { createResourceLocatorString } from '../../../../util/routes';

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <button className={css.searchSubmit}>
        <div className={iconClass}>
          <IconSearchDesktop />
        </div>
      </button>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <input
              className={isMobile ? css.mobileInput : css.desktopInput}
              {...input}
              id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              data-testid={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              ref={inputRef}
              type="text"
              // placeholder={intl.formatMessage({
              //   id: 'TopbarSearchForm.placeholder',
              // })}
              placeholder = "Keyword"
              autoComplete="off"
            />
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange,currentPage } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        return (
          <>
        
          <LocationAutocompleteInput
            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
          />
          </>
        );
      }}
    />
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
    this.state = {
      keywords: this.props.initialValues.keywords || null,
      location: this.props?.initialValues?.location || null,
    };
  }

  onChange(location) {
    this.setState({ location: location })
    const { appConfig, onSubmit } = this.props;
    if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      if (location && !this.state.keywords) {
        onSubmit({ location });
      }
      else if (location && this.state.keywords) {
        let keywords = this.state.keywords
        onSubmit({ location, keywords });
      }
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  onSubmit(values) {
    const { appConfig, onSubmit } = this.props;
    if (isMainSearchTypeKeywords(appConfig)) {
      onSubmit({ keywords: values.keywords });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  // const [location, setSearchLocation] = useState(null);
  // const [keywords, setKeyword] = useState(null);

   handleKeywordChange = (e) => {
    // this.state.setKeyword(e.target.value);
    this.setState({ keywords: e.target.value });
  };
  handleKeyDown = (event) => {

    const { appConfig, routeConfiguration, currentSearchParams, history} = this.props;

    if (event.key === 'Enter') {
      // handleSubmit({  keywords: event.currentTarget.value  }); // Call your submit function?
      const { search, selectedPlace } = this.state.location || {};
      // const { origin, bounds } = selectedPlace || {};
      // const originMaybe = isOriginInUse(appConfig) ? { origin } : {};
      
      const bounds = this?.props?.currentSearchParams?.bounds || {};
      const searchParams = search && event.currentTarget.value ? {
        ...currentSearchParams,
        // ...originMaybe,
        address: search,
        keywords: event.currentTarget.value,
      } : search && !(event.currentTarget.value) ? {
        ...currentSearchParams,
        // ...originMaybe,
        address: search,
      } : {
        keywords: event.currentTarget.value,
      };

      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
 
    }
  };
  handleSubmit1 = (values) => {
    const { appConfig, routeConfiguration, currentSearchParams, history} = this.props;
    
    values.keywords = this.state.keywords? this.state.keywords:null;
    values.location = this.state.location;
    
    const topbarSearchParams = () => {
      
      if (values?.keywords && !(values?.location)) {
        return { keywords: values?.keywords };
      }

      // Topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = isOriginInUse(appConfig) ? { origin } : {};

      if (values?.keywords && values?.location?.search.length >= 1) {
        return {
          ...originMaybe,
          address: search,
          bounds,
          keywords: values?.keywords
        };
      }
      else if (values?.keywords && !(values?.location?.search.length >= 1)) {
        return {
          keywords: values?.keywords
        };
      }
      else {
        return {
          ...originMaybe,
          address: search,
          bounds
        }
      }
    };

    const searchParams = ((values?.keywords && values?.location?.search.length >= 1) || (values?.location?.search.length >= 1)) ? {
      ...currentSearchParams,
      ...topbarSearchParams(),
    } : {
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }; 
  

  render() {
    const { onSubmit, appConfig, currentPage, currentSearchParams,routeConfiguration,history,...restOfProps } = this.props;
    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isKeywordsSearch ? this.onSubmit : onSubmit;
    return (
      <FinalForm
        {...restOfProps}
        onSubmit={submit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          return (
            <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
              {isKeywordsSearch && currentPage !== 'LandingPage'? (
                <>
                <KeywordSearchField
                  keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                  iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                />
                
                </>
              ) : (               
                <>
                {currentPage === 'LandingPage'?
                <LocationSearchField                
                desktopInputRootClass={desktopInputRootClass}
                className={css.formControl}
                intl={intl}
                isMobile={isMobile}
                inputRef={this.setSearchInputRef}
                onLocationChange={this.onChange}
                currentPage = {currentPage}
              />
                :null}
                { currentPage == 'LandingPage' ? null:
                <div className={css. searchBox}>       
                  {currentPage !== 'LandingPage' ? <div desktopInputRootClass={desktopInputRootClass} className ={css.locationLabel}>Location</div>:null}         
                <LocationSearchField
                  desktopInputRootClass={desktopInputRootClass}
                  className={css.formControl}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                  onLocationChange={this.onChange}
                  currentPage = {currentPage}
                />
                { currentPage == 'LandingPage' ? null:
                
                <div >
            <label htmlFor="Keyword">Keyword</label>
            <input
              type="text"
              className={css.formControl}
              id="Keyword"
              value={this.state.keywords}
              placeholder="Type Events or Services"
              onChange={this.handleKeywordChange}
              onKeyDown={(e) =>this.handleKeyDown(e)}
            />
            </div>
              }
              {currentPage !== 'LandingPage' ?
              
            <button              
              type="button"   
              className={css.primaryButton}         
              onClick={(values) => this.handleSubmit1(values)}
            >
              {/* <span><img src={SearchIcon} alt="Search Icon" /></span> */}
              <span><img src={searchIcon} alt="Search Icon" /></span>
              Search
            </button>
          :null}
                </div>
                }
                
                </>
              
              )}
            </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
