/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import Services from './img/services.png';
import Inbox from './img/inbox.png';
import Listing from './img/listing.png';
import Dashboard from './img/dashboard.png';
import RequestedQuotes from './img/requested-quotes.png';
import SubmittedQuotes from './img/submitted-quotes.png';
import Profile from './img/profile.png';
import Settings from './img/settings.png';
import Logout from './img/logout.png';
import Logologin from './img/logo.svg';
import Loginbg from './img/loginbg.png';

import {
  RightOutlined
} from '@ant-design/icons';

import {
  Avatar,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';

import css from './TopbarMobileMenu.module.css';
import UserVerificationComponent from '../../../../Common/profileVerificationPopup';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    isProvider,
    isAdmin,
  } = props;

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const user = ensureCurrentUser(currentUser);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });
  const isUserVerified = currentUser?.attributes?.profile?.publicData?.isUserVerified || false;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
      <img src={Logologin} className={css.logologin} />
      <div className={css.loginbg}>
        <div className={css.ripple1}></div>
        <div className={css.ripple2}></div>
        <div className={css.ripple3}></div>
      </div>
      <img src={Loginbg} className={css.loginimg} />
      
      <div className={css.btnFooter}>
        <h4>Welcome to a World of <span>Event Services!</span></h4>
        <p>Where every event comes to life.</p>
      <a href='/login' className={css.loginbtn}>Login</a>
      <a href='/signup' className={css.signupbtn}>Sign Up</a>
      </div>


      </div>




    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const isCustomer = currentUser?.attributes?.profile?.publicData?.UserType === 'customer';
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';
  const [isPopupOpen, setPopupOpen] = useState(false);
  const enablePopup = () => {
    setPopupOpen(true);
  }
  const closePopup = () => {
    setPopupOpen(false);
  };

  const redirectTo = (name, params) => {

    history.push(
      createResourceLocatorString(
        name,
        routeConfiguration,
        params,
        {}
      )
    );
  }

  return (
    <div className={css.root}>
      <div className={css.contentGreeting}>
        <Avatar className={css.avatar} user={currentUser} />
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
      </div>
      <div className={css.content}>
        <div className={css.accountLinksWrapper}>
          {isProvider ? (
            <>
              <button
                className={classNames(
                  css.navigationLink,
                  currentPageClass(`InboxPage:${inboxTab}`)
                )}
                onClick={() => {
                  let name = isProvider ? 'DashboardPage' : 'SettingPage';
                  let params = isProvider ? { tab: 'bookings' } : { tab: 'setting' };
                  localStorage.setItem('selectedTab', isProvider ? '100' : '102');
                  // localStorage.setItem('openTab', '1');
                  redirectTo(name, params);
                }}
              >
                <span>
                  <img src={Dashboard} />
                  <FormattedMessage id="TopbarMobileMenu.dashboardLink" />
                </span>
                <RightOutlined className={css.menuArrow} />
              </button>

              <button
                className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
                onClick={() => {
                  let name = 'InboxPage';
                  let params = { tab: currentUserHasListings ? 'sales' : 'orders' };
                  localStorage.setItem('selectedTab', currentUserHasListings ? '201' : '200');
                  localStorage.setItem('openTab', currentUserHasListings ? '201' : '200');
                  
                  redirectTo(name, params);
                }}
              >
                <span>
                  <img src={Inbox} />
                  <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                  {notificationCount ? <div className={css.notificationDott} /> : null}
                </span>
                <RightOutlined className={css.menuArrow} />
              </button>

              <button
                className={classNames(
                  css.navigationLink,
                  currentPageClass(`InboxPage:${inboxTab}`)
                )}
                onClick={() => {
                  let name = 'ManageListingsPage';
                  let params = {};
                  localStorage.setItem('selectedTab', '3');
                  // localStorage.setItem('openTab', '3');
                  redirectTo(name, params);
                }}
              >
                <span>
                  <img src={Listing} />
                  <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                </span>
                <RightOutlined className={css.menuArrow} />
              </button>
            </>
          ) : isAdmin ? (
            <>
            <button
              className={classNames(
                css.navigationLink,
                currentPageClass(isProvider ? 'DashboardPage' : 'SettingPage')
              )}
              onClick={() => {
                let name = isProvider ? 'DashboardPage' : 'SettingPage';
                let params = isProvider ? { tab: 'bookings' } : { tab: 'setting' };
                localStorage.setItem('selectedTab', isProvider ? '100' : '102');
                localStorage.setItem('openTab', '1');
                redirectTo(name, params);
              }}
            >
              <span>
                <img src={RequestedQuotes} />
                <FormattedMessage id="TopbarMobileMenu.dashboardLink" />
              </span>
              <RightOutlined className={css.menuArrow} />
            </button>

            <button
              className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
              onClick={() => {
                let name = 'InboxPage';
                let params = { tab: currentUserHasListings ? 'sales' : 'orders' };
                localStorage.setItem('selectedTab', currentUserHasListings ? '201' : '200');
                localStorage.setItem('openTab', '2');
                redirectTo(name, params);
              }}
            >
              <span>
                <img src={Inbox} />
                <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                {notificationCount ? <div className={css.notificationDott} /> : null}
              </span>
              <RightOutlined className={css.menuArrow} />
            </button>

            </>
          ) : 
          
          <button
              className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
              onClick={() => {
                let name = 'InboxPage';
                let params = { tab: currentUserHasListings ? 'sales' : 'orders' };
                localStorage.setItem('selectedTab', currentUserHasListings ? '201' : '200');
                localStorage.setItem('openTab', '2');
                redirectTo(name, params);
              }}
            >
              <span>
                <img src={Inbox} />
                <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                {notificationCount ? <div className={css.notificationDott} /> : null}
              </span>
              <RightOutlined className={css.menuArrow} />
            </button>

          }

          
          
          <button
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            onClick={() => {
              let name = 'ProfileSettingsPage';
              let params = {};
              localStorage.setItem('selectedTab', '4');
              // localStorage.setItem('openTab', '4');
              redirectTo(name, params);
            }}
          >
            <span>
              <img src={Profile} />
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </span>
            <RightOutlined className={css.menuArrow} />
          </button>
          <button
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            onClick={() => {
              let name = 'AccountSettingsPage';
              let params = {};
              localStorage.setItem('selectedTab', '500');
              localStorage.setItem('openTab', '5');
              redirectTo(name, params);
            }}
          >
            <span>
              <img src={Settings} />
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </span>
            <RightOutlined className={css.menuArrow} />
          </button>
          <span
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
            onClick={onLogout}
          >
            <span>
              <img src={Logout} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </span>
            <RightOutlined className={css.menuArrow} />
          </span>
        </div>
        {/* <div className={css.customLinksWrapper}>{extraLinks}</div> */}
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        {isUserVerified ? (
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        ) : (
          <a className={css.createNewListingLink} name="NewListingPage" onClick={enablePopup}>
            <FormattedMessage id="ManageListingsPage.createListing" />
          </a>
        )}
        {isPopupOpen && (
          <UserVerificationComponent
            isOpen={isPopupOpen}
            onClose={closePopup}
            isCustomer={isCustomer}
            isUserVerified={isUserVerified}
          />
        )}

        <NamedLink className={css.createNewListingBtn} name="SearchPage">
          <span>Explore our services</span>
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
