import React, { useState } from 'react';
import { Modal } from '../components'; // Ensure this path is correct
import { FormattedMessage } from 'react-intl';
import  css  from './profileVerificationPopup.module.css';

// Define the functional component with proper props
const UserVerificationComponent = ({isOpen, openListing,onClose, isCustomer, isUserVerified }) => {
  // Function to manage scrolling and logging
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };

  // Function to handle closing of the modal
  const close = () => {
    if (onClose) {
      onClose(); // Call the onClose function passed via props
    }
  };

  let message = null;
  if(openListing && !isUserVerified ){
    message = <FormattedMessage id="EditListingPage.listingOpenPopup" />;
  }else if (isCustomer) {
    message = <FormattedMessage id="EditListingPage.customerVerificationPopup" />;
  }
  // If the user is not a customer and is not verified, show User Verification
  else if (!isUserVerified) {
    message = <FormattedMessage id="EditListingPage.userVerificationPopup" />;
  }

  return (
    <div>
      <div className={css.modaloverlay}>
      <div className={css.modalcontent}>
        <button onClick={onClose} className={css.closebutton}>&times;</button>
        <div style={{ margin: '1rem', textAlign: 'justify'}}>
       {message}
      </div>
      </div>
    </div>      
    </div>
  );
};

export default UserVerificationComponent;
