import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';

import {
  MoreOutlined,
  PlusOutlined,
  HeartOutlined,
  UserOutlined,
  DownOutlined,
  RightOutlined
} from '@ant-design/icons';
import { Button, Drawer, Badge } from 'antd';

import Logo from './img/logo.svg';

import Makeup from './img/Makeup-and-Hair-Artists.png';
import Photographers from './img/Photographers-Videographers.png';
import Cook from './img/cook-chef.png';
import Cakes from './img/cakes.png';
import HennaArtists from './img/henna-artists.png';
import Venue from './img/venue.png';
import Decorators from './img/decorators.png';
import Catering from './img/catering.png';
import EventPlanner from './img/eventplanner.png';
import Entertainer from './img/entertainer.png';
import Hospitality from './img/hospitality.png';
import Celebrant from './img/celebrant.png';
import Transportation from './img/transportation.png';
import PartyProps from './img/partyprops.png';


import Inbox from './img/inbox.png';
import Dashboard from './img/dashboard.png';
import Listing from './img/listing.png';
import RequestedQuotes from './img/requested-quotes.png';
import SubmittedQuotes from './img/submitted-quotes.png';
import Profile from './img/profile.png';
import Settings from './img/settings.png';
import Logout from './img/logout.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import { isOriginInUse } from '../../../../util/search';

// const SignupLink = () => {
//   return (
//     <NamedLink name="SignupPage" className={css.topbarLink}>
//       <span className={css.topbarLinkLabel}>
//         <FormattedMessage id="TopbarDesktop.signup" />
//       </span>
//     </NamedLink>
//   );
// };

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <UserOutlined className={css.navIcon} />
        <div className={css.topbarLogin}>
          <FormattedMessage id="TopbarDesktop.login" />
          <span className={css.textLogin}>Account</span>
        </div>
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <></>
    // <NamedLink
    //   className={css.topbarLink}
    //   name="InboxPage"
    //   params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    // >
    //   <span className={css.topbarLinkLabel}>
    //     <FormattedMessage id="TopbarDesktop.inbox" />
    //     {notificationDot}
    //   </span>
    // </NamedLink>
  );
};

const DashboardLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <></>
    // <NamedLink
    //   className={css.topbarLink}
    //   name="DashboardPage"
    //   params={{ tab: 'bookings' }}
    // >
    //   <span className={css.topbarLinkLabel}>
    //     <FormattedMessage id="TopbarDesktop.dashboard" />
    //   </span>
    // </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout, currentUserHasListings, notificationCount, history, routeConfiguration, isProvider, isAdmin }) => {

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDotIn} /> : null;
  const hasNotification = notificationCount > 0 ? true : false;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const firstName = currentUser?.attributes?.profile?.firstName;

  const redirectTo = (name, params) => {

    history.push(
      createResourceLocatorString(
        name,
        routeConfiguration,
        params,
        {}
      )
    );
  }

  if (isProvider || isAdmin) {
    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          {/* <UserOutlined className={css.userIcon} /> */}

          <Badge dot={hasNotification}>
            <Avatar className={css.navAvatar} user={currentUser} disableProfileLink />
          </Badge>
          <div>
            <span className={css.userSalute}>Hello!</span>
            <span>{firstName}</span>
          </div>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="DashboardPage">
            <button className={classNames(css.menuLink, currentPageClass('DashboardPage'))}
              onClick={() => {
                let name = isProvider ? 'DashboardPage' : 'SettingPage';
                let params = isProvider ? { tab: 'bookings' } : { tab: 'setting' };
                localStorage.setItem('selectedTab', isProvider ? '100' : '102');
                localStorage.setItem('openTab', '1');
                redirectTo(name, params);
              }}
            >
              <span className={css.menuItemBorder} />
              <img src={Dashboard} />
              <span>Dashboard</span>
              <RightOutlined className={css.menuArrow} />
            </button>
          </MenuItem>
          <MenuItem key="InboxPage">
            <button className={classNames(css.menuLink, currentPageClass('InboxPage'))}
              onClick={() => {
                let name = 'InboxPage';
                let params = { tab: currentUserHasListings ? 'sales' : 'orders' };
                localStorage.setItem('selectedTab', currentUserHasListings ? '201' : '200');
                localStorage.setItem('openTab', '2');
                redirectTo(name, params);
              }}
            >
              <span className={css.menuItemBorder} />
              <img src={Inbox} />

              <span>Inbox</span>
              {/* <Badge count={101}>
              </Badge> */}
              {notificationDot}
              <RightOutlined className={css.menuArrow} />
            </button>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <button className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
              onClick={() => {
                let name = 'ManageListingsPage';
                let params = {};
                localStorage.setItem('selectedTab', '3');
                localStorage.setItem('openTab', '3');
                redirectTo(name, params);
              }}
            >
              <span className={css.menuItemBorder} />
              <img src={Listing} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
              <RightOutlined className={css.menuArrow} />
            </button>
          </MenuItem>
          {/* <MenuItem key="RequestedQuotes">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('RequestedQuotes'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <img src={RequestedQuotes} />
              <span>Requested Bids</span>
              <RightOutlined className={css.menuArrow} />
            </NamedLink>
          </MenuItem> */}
          {/* <MenuItem key="SubmittedQuotes">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('SubmittedQuotes'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <img src={SubmittedQuotes} />
              <span>Submitted Bids</span>
              <RightOutlined className={css.menuArrow} />
            </NamedLink>
          </MenuItem> */}
          <MenuItem key="ProfileSettingsPage">
            <button className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
              onClick={() => {
                let name = 'ProfileSettingsPage';
                let params = {};
                localStorage.setItem('selectedTab', '4');
                localStorage.setItem('openTab', '4');
                redirectTo(name, params);
              }}
            >
              <span className={css.menuItemBorder} />
              <img src={Profile} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
              <RightOutlined className={css.menuArrow} />
            </button>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <button className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
              onClick={() => {
                let name = 'AccountSettingsPage';
                let params = {};
                localStorage.setItem('selectedTab', '500');
                localStorage.setItem('openTab', '5');
                redirectTo(name, params);
              }}
            >
              <span className={css.menuItemBorder} />
              <img src={Settings} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
              <RightOutlined className={css.menuArrow} />
            </button>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <img src={Logout} />
              <FormattedMessage id="TopbarDesktop.logout" />
              <RightOutlined className={css.menuArrow} />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {/* <UserOutlined className={css.userIcon} /> */}

        <Badge dot={hasNotification}>
          <Avatar className={css.navAvatar} user={currentUser} disableProfileLink />
        </Badge>
        <div>
          <span className={css.userSalute}>Hello!</span>
          <span>{firstName}</span>
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <button className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            onClick={() => {
              let name = 'InboxPage';
              let params = { tab: currentUserHasListings ? 'sales' : 'orders' };
              localStorage.setItem('selectedTab', currentUserHasListings ? '201' : '200');
              localStorage.setItem('openTab', '2');
              redirectTo(name, params);
            }}
          >
            <span className={css.menuItemBorder} />
            <img src={Inbox} />

            <span>Inbox</span>
            {/* <Badge count={101}>
            </Badge> */}
            {notificationDot}
            <RightOutlined className={css.menuArrow} />
          </button>
        </MenuItem>

        {/* <MenuItem key="ManageListingsPage">
          <button className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            onClick={() => {
              let name = 'ManageListingsPage';
              let params = {};
              localStorage.setItem('selectedTab', '3');
              localStorage.setItem('openTab', '3');
              redirectTo(name, params);
            }}
          >
            <span className={css.menuItemBorder} />
            <img src={Listing} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            <RightOutlined className={css.menuArrow} />
          </button>
        </MenuItem> */}        
        
        
        {/* <MenuItem key="RequestedQuotes">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('RequestedQuotes'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <img src={RequestedQuotes} />
            <span>Requested Bids</span>
            <RightOutlined className={css.menuArrow} />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="SubmittedQuotes">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SubmittedQuotes'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <img src={SubmittedQuotes} />
            <span>Submitted Bids</span>
            <RightOutlined className={css.menuArrow} />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <button className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            onClick={() => {
              let name = 'ProfileSettingsPage';
              let params = {};
              localStorage.setItem('selectedTab', '4');
              localStorage.setItem('openTab', '4');
              redirectTo(name, params);
            }}
          >
            <span className={css.menuItemBorder} />
            <img src={Profile} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            <RightOutlined className={css.menuArrow} />
          </button>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <button className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            onClick={() => {
              let name = 'AccountSettingsPage';
              let params = {};
              localStorage.setItem('selectedTab', '500');
              localStorage.setItem('openTab', '5');
              redirectTo(name, params);
            }}
          >
            <span className={css.menuItemBorder} />
            <img src={Settings} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            <RightOutlined className={css.menuArrow} />
          </button>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <img src={Logout} />
            <FormattedMessage id="TopbarDesktop.logout" />
            <RightOutlined className={css.menuArrow} />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    currentSearchParams
  } = props;

  const isProvider = currentUser?.attributes?.profile?.publicData?.UserType == 'provider';
  const isAdmin = currentUser?.attributes?.profile?.publicData?.isAdmin;

  const [mounted, setMounted] = useState(false);
  const [open, setOpen] = useState(false);

  const [location, setSearchLocation] = useState(null);
  const [keywords, setKeyword] = useState(null);

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (values) => {
    const topbarSearchParams = () => {
      if (values?.keywords && !(values?.location)) {
        return { keywords: values?.keywords };
      }

      // Topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location?.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      if (values?.keywords) {
        return {
          ...originMaybe,
          address: search,
          bounds,
          keywords: values?.keywords
        };
      }
      else {
        return {
          ...originMaybe,
          address: search,
          bounds
        }
      }
    };

    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const dashboardLinkMaybe = (authenticatedOnClientSide && isProvider) ? (
    <DashboardLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      currentPage={currentPage}
      currentUser={currentUser}
      onLogout={onLogout}
      currentUserHasListings={currentUserHasListings}
      notificationCount={notificationCount}
      history={history}
      routeConfiguration={routeConfiguration}
      isProvider={isProvider}
      isAdmin={isAdmin}
    />
  ) : null;

  //const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  // Conditionally render TopbarSearchForm based on the currentPage
  const topbarSearchFormMaybe =
    currentPage !== 'LandingPage' && currentPage !== 'SignupPage' && currentPage !== 'LoginPage' ? (
      <>

        <TopbarSearchForm
          className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={onSearchSubmit}
          initialValues={initialSearchFormValues}
          appConfig={config}
          currentPage={currentPage}
          routeConfiguration={routeConfiguration}
          currentSearchParams={currentSearchParams}
          history={history}

        />
      </>


    ) : null;

  const redirectToSearchPage = (queryParams) => {

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
    onClose();
  }

  return (
    <nav className={classes}>
      <div className={css.headerLeft}>
        <Menu>
          <MenuLabel className={css.serviceLink} isOpenClassName={css.profileMenuIsOpen}>
            <Button type="" className={css.drawerBtn}>
              <MoreOutlined />
              <MoreOutlined />
              <MoreOutlined />
            </Button>
          </MenuLabel>
          <MenuContent className={css.serviceContainer}>
            <MenuItem key="" className={css.serviceItem}>
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'event-planner-and-coordinator',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={EventPlanner} />
                </span>
                <span>Event Planners</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'venue',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Venue} />
                </span>
                <span>Venues</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'decorators',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Decorators} />
                </span>
                <span>Decorators</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'catering',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Catering} />
                </span>
                <span>Catering</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'cakes',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Cakes} />
                </span>
                <span>Cakes</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'cook-chef',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Cook} />
                </span>
                <span>Private Chefs</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'makeup-and-hair-artists',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Makeup} />
                </span>
                <span>Makeup & Hair Artists</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'henna-artists',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={HennaArtists} />
                </span>
                <span>Henna Artists</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'photographers-videographers',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Photographers} />
                </span>
                <span>Photographers</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'celebrant-services',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Celebrant} />
                </span>
                <span>Celebrants</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'entertainer',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Entertainer} />
                </span>
                <span>Entertainers</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'hospitality-staff',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Hospitality} />
                </span>
                <span>Hospitality Staff</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'transportation',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={Transportation} />
                </span>
                <span>Transportation</span>
              </button>
            </MenuItem>
            <MenuItem key="">
              <button
                onClick={() => {
                  let queryParams = {
                    pub_categoryLevel1: 'party-props-and-hire',
                  };
                  redirectToSearchPage(queryParams);
                }}
                className={css.anchorLess}
              >
                <span className={css.anchorSpan}>
                  <img src={PartyProps} />
                </span>
                <span>Party Props & Hire</span>
              </button>
            </MenuItem>
          </MenuContent>
        </Menu>

      </div>
      <NamedLink name="LandingPage" to={{}}>
        <img src={Logo} className={css.logoLink} />
      </NamedLink>

      {/* <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
      /> */}

      {topbarSearchFormMaybe}

      <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
        currentUser={currentUser}
      />

      {/* {inboxLinkMaybe} */}
      {dashboardLinkMaybe}
      {profileMenuMaybe}
      {/* {signupLinkMaybe} */}
      {loginLinkMaybe}
    </nav>
  );
};


TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
